import { notification, Modal, Form } from "antd";
import { ModalContent, FormItem } from "@ra-lib/admin";
import config from "src/commons/config-hoc";
import { outToLogin } from "src/commons";
export default config({
  modal: {
    title: "修改密码",
    width: 500,
  },
})(function PasswordModal(props) {
  const { onOk, onCancel } = props;

  function handleSubmit(values) {
    const {oldPassword,newPassword } = values;
    props.ajax.post("/orgUser/changePassword", {
      oldPassword,
      password:newPassword,
    },{
      headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
    }).then((res) => {
      if (!res.errorType) {
        onOk();
        Modal.warning({
          title: "温馨提示!",
          content: <span>密码修改成功，请重新登录</span>,
          onOk: () => {
            outToLogin();
          },
        });
      } else {
        notification.success({
          message: "失败",
          description: res.message,
          duration: 2,
        });
      }
    });
  }

  const layout = {
    labelCol: { flex: "100px" },
  };

  return (
    <Form onFinish={handleSubmit}>
      <ModalContent okHtmlType="submit" onCancel={onCancel}>
        <FormItem
          {...layout}
          type="password"
          label="原密码"
          name="oldPassword"
          required
        />
        <FormItem
          {...layout}
          type="password"
          label="新密码"
          minLength={6}
          name="newPassword"
          required
        />
        <FormItem
          {...layout}
          type="password"
          label="确认新密码"
          minLength={6}
          name="checkNewPassword"
          required
          dependencies={["password"]}
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("newPassword") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("确认新密码与新密码不同！"));
              },
            }),
          ]}
        />
      </ModalContent>
    </Form>
  );
});
