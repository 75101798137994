import G6 from "@antv/g6";
import React, { useEffect, useRef } from "react";
import styles from "./style.less";
import insertCss from "insert-css";
import {
  handleData,
  hanldeRoot,
  handleAssembleData,
  findNodeById,
} from "./utils";
import {
  Rect,
  Text,
  Circle,
  Image,
  Group,
  createNodeFromReact,
} from "@antv/g6-react-node";
insertCss(`
  .g6-component-tooltip {
    background-color: rgba(0,0,0, 0.65);
    padding: 10px;
    box-shadow: rgb(174, 174, 174) 0px 0px 10px;
    width: fit-content;
    color: #fff;
    border-radius = 4px;
  }
`);
const GraphComponent = (props) => {
  const { dataSource, dates } = props;
  const containerRef = useRef(null);
  const graphRef = useRef(null);
  const dateRef = useRef(dates);
  useEffect(() => {
    dateRef.current = dates;
  }, [dates]);
  const newData = handleAssembleData(dataSource);
  const fittingString = (str, maxWidth, fontSize) => {
    const ellipsis = "...";
    const ellipsisLength = G6.Util.getTextSize(ellipsis, fontSize)[0];
    let currentWidth = 0;
    let res = str;
    const pattern = new RegExp("[\u4E00-\u9FA5]+"); // distinguish the Chinese charactors and letters
    str.split("").forEach((letter, i) => {
      if (currentWidth > maxWidth - ellipsisLength) return;
      if (pattern.test(letter)) {
        // Chinese charactors
        currentWidth += fontSize;
      } else {
        // get the width of single letter according to the fontSize
        currentWidth += G6.Util.getLetterWidth(letter, fontSize);
      }
      if (currentWidth > maxWidth - ellipsisLength) {
        res = `${str.substr(0, i)}${ellipsis}`;
      }
    });
    return res;
  };

  G6.Util.traverseTree(newData, (subtree) => {
    if (subtree.selfLevel === undefined) subtree.selfLevel = 0;
    subtree.children?.forEach(
      (child) => (child.selfLevel = subtree.selfLevel + 1)
    );
    switch (subtree.nodeType) {
      case "root":
        subtree.type = "root";
        break;
      case 0:
        subtree.type = "level1node";
        break;
      case 1:
        subtree.type = "othernode";
        break;
      default:
        subtree.type = "othernode";
    }
  });
  // root node
  G6.registerNode("root", {
    draw: (cfg, group) => {
      const size = [40, 10];
      const keyShape = group.addShape("rect", {
        attrs: {
          width: size[0],
          height: size[1],
          x: -size[0] / 2,
          y: -size[1] / 2,
          fill: "#fff",
          lineWidth: 0.5,
          radius: 1,
          stroke: "#DEE3E8",
          opacity: 1,
        },
        draggable: true,
        name: "root-keyshape",
      });
      group.addShape("text", {
        attrs: {
          text: fittingString(cfg.nodeName, size[0], 5),
          fill: "#1D2129",
          fontSize: 5,
          x: -2,
          y: 2,
          textAlign: "center",
        },
        draggable: true,
        name: "label-shape",
      });
      return keyShape;
    },
  });
  // level1 node
  G6.registerNode(
    "level1node",
    {
      draw: (cfg, group) => {
        const size = [45, 10];
        const keyShape = group.addShape("rect", {
          attrs: {
            width: size[0],
            height: size[1],
            x: -size[0] / 2,
            y: -size[1] / 2,
            fill: "#fff",
            lineWidth: 0.5,
            radius: 1,
            stroke: "#DEE3E8",
          },
          draggable: true,
          name: "level1node-keyshape",
        });
        group.addShape("text", {
          attrs: {
            text: fittingString(cfg.nodeName, size[0], 6),
            fill: "#1D2129",
            fontSize: 6,
            x: 0,
            y: 2,
            textAlign: "center",
          },
          draggable: true,
          name: "label-shape",
        });
        // edge end

        return keyShape;
      },
      update: undefined,
    },
    "rect"
  );

  // other node
  G6.registerNode(
    "othernode",
    {
      draw: (cfg, group) => {
        const size = [60, 20];
        const keyShape = group.addShape("rect", {
          attrs: {
            width: size[0],
            height: size[1],
            x: -size[0] / 2,
            y: -size[1] / 2,
            stroke: cfg.triggerCondition ? "#F53F3F" : "#21C05C",
            lineWidth: 0.5,
            fill: "#fff",
            cursor: "pointer",
            radius: 2,
          },
          draggable: true,
          name: "level1node-keyshape",
        });
        group.addShape("text", {
          attrs: {
            text: fittingString(cfg.nodeName, size[0], 6),
            fill: "rgba(19, 33, 92, 0.65)",
            fontSize: 6,
            cursor: "pointer",
            // x: 8 - size[0] / 2,
            y: -2,
            textAlign: "center",
          },
          draggable: true,
          name: "label-shape",
        });
        group.addShape("text", {
          attrs: {
            text: `${Number(cfg.indexResult).toFixed(2) || "无"}`,
            fill: "#165DFF",
            lineWidth: 0.5,
            fontSize: 8,
            cursor: "pointer",
            fontWeight: 800,
            // x: 10 - size[0] / 2,
            y: 8,
            textAlign: "center",
          },
          draggable: true,
          name: "label-shape",
        });
        return keyShape;
      },
      update: undefined,
    },
    "single-node"
  );
  // edge
  G6.registerEdge(
    "round-poly",
    {
      getControlPoints: (cfg) => {
        const { startPoint, endPoint } = cfg;

        return [
          startPoint,
          {
            x: startPoint.x,
            y: endPoint.y,
          },
          endPoint,
        ];
      },
    },
    "polyline"
  );

  useEffect(() => {
    let container = containerRef?.current;
    const width = container?.scrollWidth;
    const height = container?.scrollHeight || 500;
    const tooltip = new G6.Tooltip({
      // offsetX and offsetY include the padding of the parent container
      offsetX: 20,
      offsetY: 30,
      // the types of items that allow the tooltip show up
      // 允许出现 tooltip 的 item 类型
      itemTypes: ["node"],
      // custom the tooltip's content
      // 自定义 tooltip 内容
      getContent: (e) => {
        const outDiv = document.createElement("div");
        //outDiv.style.padding = '0px 0px 20px 0px';
        const nodeName = e.item.getModel().nodeName;
        let formatedNodeName = "";
        for (let i = 0; i < nodeName.length; i++) {
          formatedNodeName = `${formatedNodeName}${nodeName[i]}`;
          if (i !== 0 && i % 20 === 0)
            formatedNodeName = `${formatedNodeName}<br/>`;
        }
        outDiv.innerHTML = `${formatedNodeName}`;
        return outDiv;
      },
      shouldBegin: (e) => {
        if (
          e.target.get("name") === "label-shape" ||
          e.target.get("name") === "mask-label-shape"
        )
          return true;
        return false;
      },
    });
    let graph = new G6.TreeGraph({
      container,
      width,
      height,
      fitView: true,
      renderer: "canvas",
      layout: {
        type: "compactBox",
        direction: "LR",
        getHeight: function () {
          return 40;
        },
        getWidth: function () {
          return 60;
        },
        getVGap: function () {
          return 5;
        },
        getHGap: function () {
          return 10;
        },
      },
      defaultEdge: {
        type: "round-poly",
        sourceAnchor: 0,
        targetAnchor: 1,
        style: {
          lineWidth: 0.5,
          stroke: "#DEE3E8",
        },
      },
      defaultNode: {
        anchorPoints: [
          [0.5, 0.5],
          [0, 0.5],
        ],
      },
      nodeStateStyles: {
        hover: {
          fill: "#fff",
          shadowBlur: 30,
          shadowColor: "#ddd",
        },
        operatorhover: {
          "operator-box": {
            opacity: 1,
          },
        },
      },
      modes: {
        // default: ['zoom-canvas', 'drag-canvas', 'collapse-expand']
      },
      plugins: [tooltip],
    });
    graphRef.current = graph;
    graph.clear();
    graph.data(newData);
    graph.render();
  }, []);
  if (graphRef.current) {
    graphRef.current.on("node:click", (evt) => {
      const item = evt.item; // 被操作的节点 item
      const {
        _cfg: {
          model: { structId },
        },
      } = item;
      const node = findNodeById(structId, dataSource);
      if (node && node.nodeType === 1) {
        const {
          indexId,
          structId: newStructId,
          userId,
          name,
        } = node.skipResponse;
        window.open(
          `/front/target-detail?name=${name}&structId=${newStructId}&indexIds=${indexId}&userId=${userId}&dateCycle=${
            dateRef.current[0].format("YYYY-MM-DD") +
            "~" +
            dateRef.current[1].format("YYYY-MM-DD")
          }`,
          "_blank"
        );
      }
    });
  }
  useEffect(() => {
    graphRef.current.clear();
    graphRef.current.data(newData);
    graphRef.current.render();
    const zoom = graphRef.current.getZoom().toFixed(2);
    const toToal = 400 * (dataSource && dataSource.length); //现在的高度除以倍数获得应该的高度
    graphRef.current.changeSize(containerRef.current.scrollWidth, toToal); //重新设置高度
  }, [newData]);
  return <div ref={containerRef} className={styles.graph}></div>;
};

export default GraphComponent;
