import React, { useState, useEffect, useRef } from "react";
import config from "src/commons/config-hoc";
import profileIcon from "../../image/profile_title.png";
import BarIcon from "../../image/bar_icon.png";
import LineIcon from "../../image/line_icon.png";
import PieIcon from "../../image/pie_icon.png";
import moment from "moment";
import PieItem from "./pieItem";
import BarAndLineItem from "./barAndLineItem";
import {
  handeNewDrillData,
  handelDrillParams,
  handlePieData,
  handleBarAndLineMixData,
  renderTooltip,
} from "./utils";
import { getDate } from "../report/utils";
import {
  DownloadOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { PageContent, getQuery } from "@ra-lib/admin";
import { UniveralEcharts } from "../../components";
import { formatNewChartViewData } from "../report/components/chartItem/utils";
import { dateButtonType } from "../report/constant";
import cx from "classnames";
import {
  DatePicker,
  Button,
  Form,
  Space,
  Spin,
  Empty,
  Breadcrumb,
  Radio,
} from "antd";
import style from "./style.less";

const RangePicker = DatePicker.RangePicker;
export default config({
  path: "/front/profile-detail",
  title: "图表详情",
})(function ProfileDetail(props) {
  const {
    pageChartId,
    type,
    activeInd,
    startTime,
    endTime,
    profileTitle,
    interval,
    name,
  } = getQuery();
  const [drillDownNode, setDrillDownNode] = useState("");
  const [detailParams, setDetailParams] = useState({});
  const [ascOrder, setAscOrder] = useState(false);
  const dateRef = useRef();
  const [dates, setDates] = useState([
    moment(startTime, "YYYY-MM-DD HH:mm:ss"),
    moment(endTime, "YYYY-MM-DD HH:mm:ss"),
  ]);
  const [loading2, setLoading2] = useState(false);
  const [showTip, setShowTip] = useState(true);
  const [pieData, setPieData] = useState([]);
  const [buttonActiveInd, setButtonActiveInd] = useState(
    Number(activeInd) || -1
  );
  const [breadList, setBreadList] = useState([{ name: "默认" }]);
  const [dataType, setdataType] = useState("");
  const [weekType, setWeekType] = useState(interval);
  const [options, setOptions] = useState({});
  const [barAndLineData, setBarAndLineData] = useState([]);
  const [loading, setLoading] = useState(false);
  const getDateCycle = (dateType) => {
    let nowDate = [];
    props.ajax.post("/initDate/queryDateCycle", { dateType }).then((res) => {
      const { startDate, endDate } = res.data || {};
      console.log(startDate, endDate);
      nowDate = [moment(startDate), moment(endDate)];
      setDates(nowDate);
      dateRef.current = nowDate;
      getDetailData(true);
    });
  };
  const handleChangeDate = (type, ind,dateType) => {
    const dataTypeEnum = [1, 2, 3, 4];
    setButtonActiveInd(ind);
    if (!dataTypeEnum.includes(dateType)) {
       const nowDate = getDate(type);
       setDates(nowDate);
       dateRef.current = nowDate;
       getDetailData(true);
    } else {
      getDateCycle(dateType);
    }
  };
  
  // 请求详情数据
  /***
   * @param Boolean isSaveBreadList 是否修改面包屑
   */
  const getDetailData = (isSaveBreadList) => {
    setLoading(true);
    let newDrillDownKey = "/";
    if (isSaveBreadList && breadList.length !== 1) {
      const {
        data: { drillDownKey },
        name,
      } = breadList.at(-1);
      newDrillDownKey = `${drillDownKey}/${name}`;
    } else {
      newDrillDownKey = "/";
    }
    const isModify = newDrillDownKey === "/";
    props.ajax
      .post("/profile/chart/data", {
        pageChartId: pageChartId,
        startTime: dateRef.current[0].format("YYYY-MM-DD HH:mm:ss"),
        endTime: dateRef.current[1].endOf("D").format("YYYY-MM-DD HH:mm:ss"),
        interval: weekType,
        drillDownKey: newDrillDownKey,
        drillDownNode,
        ascOrder,
      })
      .then((res) => {
        isModify && setBreadList([{ name: "默认" }]);
        if (res && res.code === 0 && res?.data?.series?.length !== 0) {
          setLoading(false);
          const { data = {}, map = {} } = res;
          // 这里需要根据map里的canDrillDown 来设置部门or人员
          const { canDrillDown } = map;
          if (canDrillDown) {
            setdataType("01");
          } else {
            setdataType("02");
          }
          setDetailParams({ ...map, drillDownKey: newDrillDownKey });
          data.type = type;
          const params = {
            isDetail: true,
          };
          if (type === "barAndLineMix") {
            const barAndLineDataSource = handleBarAndLineMixData(data, map);
            setBarAndLineData(barAndLineDataSource);
          } else if (type === "pie") {
            const pieDataSoure = handlePieData(data, map);
            setPieData(pieDataSoure);
          } else {
            if (data.categories.length === 1) {
              isModify && setBreadList([{ name: "默认" }]);
            } else {
              isModify && setBreadList([{ name: "默认" }]);
            }
            const finalData = handeNewDrillData(data, map);
            const { option = {} } = formatNewChartViewData(
              finalData,
              map,
              params
            );
            setOptions({ ...option });
          }
        } else {
          setLoading(false);
          const { map = {} } = res;
          setDetailParams({ ...map, drillDownKey: newDrillDownKey });
          setOptions({});
          setPieData([]);
          setBarAndLineData([]);
        }
      });
  };
  // 处理下钻
  const handleDrill = (params, isAddBread) => {
    getDrillData(params, isAddBread);
  };
  // 渲染面包屑下左侧的图标
  const renderIcon = (type) => {
    switch (type) {
      case "bar":
        return <img src={BarIcon} className={style.img} alt=""/>;
      case "line":
        return <img src={LineIcon} className={style.img} alt=""/>;
      case "pie":
        return <img src={PieIcon} className={style.img} alt=""/>;
      default:
        return <img src={LineIcon} className={style.img} alt=""/>;
    }
  };
  // 渲染折线图日/月/等
  const renderLineRadioBtn = () => {
    return (
      <Radio.Group
        defaultValue={weekType}
        buttonStyle="solid"
        onChange={(e) => {
          setWeekType(e.target.value);
        }}
      >
        <Radio.Button value="1">日</Radio.Button>
        <Radio.Button value="2">周</Radio.Button>
        <Radio.Button value="3">月</Radio.Button>
        <Radio.Button value="4">季度</Radio.Button>
      </Radio.Group>
    );
  };
  const handleClickBread = (params, index) => {
    if (index === 0) {
      getDetailData();
      // setBreadList([{ name: "默认" }]);
    } else {
      const currentList = breadList.slice(0, index + 1);
      setBreadList(currentList);
      handleDrill(params, false);
    }
  };
  // 请求下钻数据
  const getDrillData = (params, isAddBread) => {
    // 这里需要根据不同类型进行区分
    const newParams = handelDrillParams(type, params);
    const { name, canDrillDown, drillDownKey, indexId, structId } = newParams;
    if (canDrillDown) {
      setLoading(true);
      props.ajax
        .post("/profile/chart/data", {
          pageChartId: pageChartId,
          startTime: dateRef.current[0].format("YYYY-MM-DD HH:mm:ss"),
          endTime: dateRef.current[1].endOf("D").format("YYYY-MM-DD HH:mm:ss"),
          interval: weekType,
          drillDownKey: `${drillDownKey}/${name}`,
          drillDownNode,
          ascOrder,
        })
        .then((res) => {
          if (res && res.code === 0 && res?.data?.series?.length !== 0) {
            setLoading(false);
            const { data = {}, map = {} } = res;
            // 这里需要根据map里的canDrillDown 来设置部门or人员
            const { canDrillDown } = map;
            if (canDrillDown) {
              setdataType("01");
            } else {
              setdataType("02");
            }
            setDetailParams({
              ...map,
              drillDownKey: `${drillDownKey}/${name}`,
            });
            data.type = type;
            if (isAddBread) {
              setBreadList([
                ...breadList,
                { name, data: { canDrillDown, drillDownKey } },
              ]);
            }
            const params = {
              isDetail: true,
            };
            if (type === "barAndLineMix") {
              const barAndLineDataSource = handleBarAndLineMixData(data, map);
              setBarAndLineData(barAndLineDataSource);
            } else if (type === "pie") {
              const pieDataSoure = handlePieData(data, map);
              setPieData(pieDataSoure);
            } else {
              const finalData = handeNewDrillData(data, map);
              const { option = {} } = formatNewChartViewData(
                finalData,
                map,
                params
              );
              setOptions({ ...option });
            }
          } else {
            setLoading(false);
            setOptions({});
            setPieData([]);
            setBarAndLineData([]);
          }
        });
    } else {
      let isCockit = true;
      window.open(
        `/front/target-detail?name=${name}&structId=${structId}&indexIds=${indexId}&userId=${name}&dateCycle=${
          dateRef.current[0].format("YYYY-MM-DD") +
          "~" +
          dateRef.current[1].format("YYYY-MM-DD")
        }&isCockit=${isCockit}&pageChartId=${pageChartId}`,
        "_blank"
      );
    }
  };
  /**
   *  渲染面包屑数据
   */
  const renderBread = () => {
    return (
      <Breadcrumb separator="/">
        {breadList.map((item, index) => {
          return (
            <Breadcrumb.Item
              key={index}
              onClick={() => {
                handleClickBread(item, index);
              }}
              style={{ cursor: "pointer" }}
            >
              {item.name}
            </Breadcrumb.Item>
          );
        })}
      </Breadcrumb>
    );
  };

  const handleClickSort = () => {
    setAscOrder(!ascOrder);
  };
  const handleChangeType = (e) => {
    setdataType(e.target.value);
    if (e.target.value === "01") {
      setDrillDownNode(true);
    } else {
      setDrillDownNode(false);
    }
  };
  // 点击进入详情页
  const handleClickDetail = () => {
    let isChart = true;
    const { structId, indexId, drillDownKey } = detailParams;
    let nodeName = "";
    nodeName = breadList.at(-1).name;
    // if(breadList.length ===1){
    //    nodeName = ''
    // }else {
    //   nodeName = drillDownKey
    // }
    window.open(
      `/front/target-detail?name=${nodeName}&structId=${structId}&indexIds=${indexId}&userId=${nodeName}&dateCycle=${
        dateRef.current[0].format("YYYY-MM-DD") +
        "~" +
        dateRef.current[1].format("YYYY-MM-DD")
      }&isChart=${isChart}&pageChartId=${pageChartId}&drillDownKey=${drillDownKey}`,
      "_blank"
    );
  };
  const handleDownload = () => {
    const params = {
      pageChartId: pageChartId,
      startTime: dateRef.current[0].format("YYYY-MM-DD HH:mm:ss"),
      endTime: dateRef.current[1].endOf("D").format("YYYY-MM-DD HH:mm:ss"),
      interval: weekType,
      drillDownNode,
      ascOrder,
    };
    let drillDownKey = "/";
    if (breadList.length !== 1) {
      const lastBread = breadList.at(-1);
      drillDownKey = `${lastBread.data.drillDownKey}/${lastBread.name}`;
    }
    params["drillDownKey"] = drillDownKey;
    setLoading2(true);
    props.ajax
      .post(
        "/profile/chart/index/data",
        {
          ...params,
        },
        {
          responseType: "blob",
        }
      )
      .then((res) => {
        if (res) {
          const blob = new Blob([res], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
          });
          const href = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = href;
          a.download = `${moment().format("YYYY_MM_DD_hh_mm")}${name}.xlsx`;
          a.click();
          URL.revokeObjectURL(a.href);
        }
        setLoading2(false);
      })
      .catch(() => {
        setLoading2(false);
      });
  };
  // 进页面初始化调用
  useEffect(() => {
    dateRef.current = [
      moment(startTime, "YYYY-MM-DD HH:mm:ss"),
      moment(endTime, "YYYY-MM-DD HH:mm:ss"),
    ];
    getDetailData();
  }, []);
  useEffect(() => {
    if (breadList.length <= 1) {
      getDetailData();
    } else {
      getDetailData(true);
    }
  }, [weekType, drillDownNode, ascOrder]);
  // 渲染空页面
  const renderEmpty = () => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          height: "100%",
          width: "100%",
        }}
      >
        <Empty style={{ width: "100%" }} description="暂无数据" />
      </div>
    );
  };
  const renderContent = () => {
    if (type === "pie") {
      return (
        <div className={style.pieWrap}>
          {pieData?.length
            ? pieData?.map((item, index) => {
                return (
                  <div className={style.pieSingle} key={index}>
                    <PieItem
                      key={index}
                      item={item}
                      getDrillData={getDrillData}
                    ></PieItem>
                  </div>
                );
              })
            : renderEmpty()}
        </div>
      );
    } else if (type === "barAndLineMix") {
      return (
        <div className={style.pieWrap}>
          {barAndLineData?.length
            ? barAndLineData?.map((item, index) => {
                return (
                  <div className={style.pieSingle} key={index}>
                    <BarAndLineItem
                      key={index}
                      item={item}
                      getDrillData={getDrillData}
                    ></BarAndLineItem>
                  </div>
                );
              })
            : renderEmpty()}
        </div>
      );
    } else {
      return (
        <div
          className={style.wrap}
          style={{
            height: options.chartHeight ? options.chartHeight : "100%",
          }}
        >
          {Object.keys(options).length === 0 ? (
            renderEmpty()
          ) : (
            <UniveralEcharts
              style={{ height: "100%", width: "100%" }}
              clickEvent={(params) => handleDrill(params, true)}
              option={options}
            />
          )}
        </div>
      );
    }
  };
  return (
    <PageContent fitHeight className={style.profileDetail}>
      <div className={style.headerTop}>
        <div className={style.headerTopLeft}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <img src={profileIcon} className={style.img} alt=""/>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{profileTitle}</Breadcrumb.Item>
            <Breadcrumb.Item>
              <span className={style.titleName}>{name}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        {/**柱状图展示*/}
        <div className={style.headerTopRight}>
          <Button
            className={style.downlandBtn}
            icon={<DownloadOutlined />}
            type="primary"
            style={{ marginRight: 20 }}
            loading={loading2}
            onClick={() => handleDownload()}
          >
            指标结果
          </Button>
          <Button
            className={style.downlandBtn}
            type="primary"
            onClick={() => handleClickDetail()}
          >
            数据详情
          </Button>
          {type === "bar" && (
            <div>
              <Radio.Group
                value={dataType}
                buttonStyle="solid"
                onChange={(e) => handleChangeType(e)}
              >
                <Radio.Button value="01">部门</Radio.Button>
                <Radio.Button value="02">人员</Radio.Button>
              </Radio.Group>
            </div>
          )}
        </div>
      </div>
      {showTip && (
        <div className={style.headerBottom}>
          <div className={style.headerBottomLeft}>{renderTooltip(type)}</div>
          <div
            className={style.headerBottomRight}
            onClick={() => setShowTip(false)}
          >
            <CloseOutlined />
          </div>
        </div>
      )}
      <div className={style.profileDetailContent}>
        <div className={style.formSetting}>
          <div className={style.form}>
            <div className={style.formLeft}>
              <Form.Item label="时间">
                <RangePicker
                  value={dates}
                  format="YYYY-MM-DD"
                  style={{ width: "240px" }}
                  onChange={(value) => {
                    if (!value) return;
                    setButtonActiveInd("");
                    setDates(value);
                    dateRef.current = value;
                    getDetailData();
                  }}
                />
              </Form.Item>
              <div className={style.buttonType}>
                <Space>
                  {dateButtonType.map((item, index) => {
                    return (
                      <span
                        className={cx(
                          style.operation,
                          buttonActiveInd === index ? style.active : ""
                        )}
                        key={item.type}
                        onClick={() => handleChangeDate(item.type, index,item.dateType)}
                      >
                        {item.name}
                      </span>
                    );
                  })}
                </Space>
              </div>
            </div>
          </div>
          <div className={style.line}></div>
          <div className={style.breadContent}>
            <div className={style.breadContentLeft}>
              {renderIcon(type)}
              <div>{renderBread()}</div>
            </div>
            <div className={style.breadContentRight}>
              {type === "bar" && (
                <div
                  onClick={handleClickSort}
                  className={style.breadContentRightSort}
                >
                  <img src={LineIcon} className={style.sortImg} alt=""/>
                  <div>排序</div>
                </div>
              )}
              {/*折线图，组合图需要展示切换按钮*/}
              {["line", "barAndLineMix"].includes(type)
                ? renderLineRadioBtn()
                : ""}
            </div>
          </div>
        </div>
        <Spin spinning={loading}>
          <div className={style.chartWrap}>{renderContent()}</div>
        </Spin>
      </div>
    </PageContent>
  );
});
